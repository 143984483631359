#element-sections-container {
	background-color: white;

	.canvas-container {
		height: 100%;
		width: 100%;
		position: relative;
		border: 1px solid #999999;
		overflow: hidden;

		.scale-bar-container {
			position: absolute;
			bottom: 3px;
			left: 3px;
		}
	}

	canvas {
		z-index: 1;
	}

	.section-drawing {
		z-index: 0;
		position: absolute;
	}

	.section-drawing-loading-spinner {
		z-index: 0;
		position: absolute;
	}

	.perspective-axes {
		align-self: start;
		max-height: 150px;
	}

	.section-axes {
		align-self: start;
		max-height: 100px;
	}

	canvas {
		border: 1px solid #999999;
	}
}
