// https://github.com/encharm/Font-Awesome-SVG-PNG

.heatmapsToolbarButton {
	background-image: url(images/fire_black.png);
	background-size: 32px;
	background-repeat: no-repeat;
	background-position: center;
}

.heatmapsToolbarButtonBlue {
	background-image: url(images/fire_68cff2.png);
	background-size: 32px;
	background-repeat: no-repeat;
	background-position: center;
	box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 1);
}

#heatmapScaleContainer {
	position: absolute;
	top: 15px;
	left: 75px;
}

#heatmapScale {
	width: 50px;
	height: 150px;
}

#heatmapScaleValue {
	width: 42px;
	text-align: center;
	background-color: rgba(255, 255, 255, 0.95);
	border-radius: 3px;
	margin-left: 4px;
	font-weight: bolder;
	font-size: 11px;
	vertical-align: middle;
	float: left;
}

#heatmapSetterContainer {
	width: 50px;
	height: 175px;
	background: #ffffff;
	border-radius: 5px;
	box-shadow: 1px 3px 10px 0 rgba(0, 0, 0, 0.4);
	position: absolute;
	bottom: 15px;
	left: 75px;
	margin-top: 8px;
}

.tooltiptext {
	visibility: hidden;
	width: 100px;
	background-color: white;
	text-align: center;
	padding: 5px;
	border-radius: 3px;
	position: absolute;
	top: -25px;
	left: 25px;
	font-size: 11px;
	z-index: 10;
}

#heatmapToleranceLabel {
	width: 34px;
	height: 20px;
	background-image: url(images/caliper.png);
	opacity: 0.9;
	position: relative;
	top: 11px;
	margin-left: 8px;
	margin-bottom: 2px;
	float: left;
}

#heatmapToleranceLabel:hover .tooltiptext {
	visibility: visible;
}

#heatmapToleranceInput {
	margin-left: 3px;
	width: 38px;
	height: 18px;
	position: relative;
	top: 11px;
	margin-bottom: 16px;
	text-align: center;
	font-size: 12px;
	float: left;
	box-shadow: none;
	border: 1px solid #dbdbdb;
	border-radius: 2px;
}

#heatmapMaxDeviationLabel {
	width: 34px;
	height: 20px;
	background-image: url(images/range.png);
	opacity: 0.9;
	position: relative;
	top: 11px;
	margin-left: 8px;
	margin-bottom: 2px;
	float: left;
}

#heatmapMaxDeviationLabel:hover .tooltiptext {
	visibility: visible;
}

#heatmapMaxDeviationInput {
	width: 38px;
	height: 18px;
	position: relative;
	top: 9px;
	margin-left: 3px;
	margin-bottom: 16px;
	text-align: center;
	font-size: 12px;
	float: left;
	box-shadow: none;
	border: 1px solid #dbdbdb;
	border-radius: 2px;
}

#heatmapResetButton {
	margin-left: 4px;
	width: 32px;
	min-width: 32px;
	max-width: 32px;
	height: 32px;
	position: relative;
	top: 10px;
	left: 4px;
	box-shadow: none;
	border: 0;
	outline: 0;
	background: url('images/reset.png') no-repeat;
	cursor: pointer;
}

#heatmapMagnitudeTooltip {
	display: none; // hidden by default
	background-color: rgba(255, 255, 255, 0.8);
	border-radius: 5px;
	height: 30px;
	width: 100px;
	position: fixed;
	text-align: center;
	z-index: 1000;
	transition: display 0.1s ease-out;
}

#heatmapMagnitudeTooltip p {
	display: table-cell;
	font-weight: bolder;
	text-align: center;
	vertical-align: middle;
}

// Loading spinner
// https://loading.io/css/
.lds-ring {
	display: inline-block;
	width: 64px;
	height: 64px;
	transform: rotate(45deg);
	transform-origin: 32px 32px;

	margin: auto;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -50px;
	margin-left: -50px;
	visibility: visible;
	text-align: center;
}

.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 101px;
	height: 101px;
	border: 10px solid #fff;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
// END loading spinner
