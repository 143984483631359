// ******************************
// ******** SR Colors ***********
// ******************************

// Please keep it synced with sr-colors.ts
:root {
	--sr-color-accent-1: #7890B2; //Yellow 2
	--sr-color-accent-2: #e60103; // Red
	--sr-color-accent-2-hover: #a80a00; // hover Red
	--sr-color-accent-3: #1c62dc; // Blue
	--sr-color-accent-4: #00c781; // Green
	--sr-color-accent-5: #ffd9d9; // Pink-error
	--sr-color-text-light: #242629; // Gray 1 texts
	--sr-color-light-1: #fafafa; // Gray 4 background
	--sr-color-light-2: #eff0f1; // Gray 5 background
	--sr-color-neutral-1: #6b717b; // Gray 2 Secondary
	--sr-color-neutral-1-hover: #53585f; // Gray primary hover
	--sr-color-neutral-2: #e1e3e5; // Gray 3 background 2
	--sr-color-neutral-3: #087cd9; // Issues Blue
	--sr-color-hover: #d3d5d8; // Gray hover secondary
	--sr-color-brand: #6b717b; // Gray 2 Primary, for now same color as gray brand
	--sr-color-brand-main: #8399b8; // Brand Yellow
	--sr-color-focus: #d3d5d8; // Focus
	--sr-color-selected: #bdc0c5; // Gray selected
	--sr-color-white: #fff; // White
	--sr-color-status-ok-background: #fef6d0; // Light green
	--sr-color-status-info-background: #e8f0fc; // Light blue
	--sr-color-status-warning-background: #ccf4e6; // Light brand yellow

	--sr-color-section-annotation: #14479e; // measurement tool

	--sr-color-verified: #00c781; // verified color
	--sr-color-deviated: #fcd116; // deviated color
	--sr-color-under-construction: #f88300; // under construction color
	--sr-color-missing: #e60103; // missing color
	--sr-color-no-data: #d3d5d8; // no data color
	--sr-color-burn-up-tick: #ff4040; // Used for burn up chart ticks
	--sr-color-color-built: #1c62dc; // built color
}
