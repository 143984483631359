@import '~sr-react-commons/src/themes/sr-theme.styles';

#root {
	height: 100vh;
	width: 100vw;
	overflow: hidden;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
	width: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
	color: rgba(0, 0, 0, 0.87);
}
